<template>

	<div class="officepc">
		<div class="gobox">
			<el-carousel ref="carousel" :height="screenHeight+'px'" trigger="click" direction="vertical" :loop="true"
				:autoplay="false">

				<el-carousel-item>
					<div class="homePage">
						<div class="One_box">
							<div class="headerBox">
								<div class="leftbox">
									<img src="../assets/images/logo.png" alt="" />
								</div>
								<div class="rightbox">
									<div class="topbox">
										<div class="title">多粒鲜果</div>
										<div class="line"></div>
										<div class="mall">
											<span>FRESH</span>
											<span>MALL</span>
										</div>
									</div>
									<div class="bbox">
										多粒鲜果优质生鲜自营商城
									</div>
								</div>
							</div>
							<div class="pageTitle">多粒鲜果</div>
							<div class="pageDesc">Multiple fresh</div>
							<div class="pageBox">
								<div class="phone ios">
									<el-popover placement="bottom" width="224" trigger="hover">
										<canvas ref="iosqrCanvas" width="200" height="200"></canvas>
										<img slot="reference" src="../assets/images/ios_download.png" alt="" />
									</el-popover>
								
								</div>
								<div class="phone android">
									<el-popover placement="bottom"  width="224" trigger="hover">
																<canvas ref="andoroidqrCanvas" width="200" height="200"></canvas>
															<img slot="reference" src="../assets/images/android_download.png" alt="" />
														</el-popover>
								
								</div>

							</div>
							<div class="pageIcon">
								<img src="../assets/images/pc01.png" alt="" />
							</div>
							<div class="pageFoot">新鲜直达 美味到家</div>
							<div class="pagePhone">
								<img src="../assets/images/pc02.png" alt="" />
							</div>
						</div>
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<div class="pageTwo">
						<div class="Two_box">
							<div class="project">商城介绍
								<div class="project_img">
									<img src="../assets/images/pc03.png" alt="" />
								</div>
								<div class="project_des">Introduction</div>
							</div>
							<div class="project_box">
								<div class="project_list">
									<div class="project_item">
										<div class="item_text">
											<div class="point"></div>
											<div class="item_title">商城背景</div>
										</div>
										<div class="item_remark">
											后疫情时代，拉动了用户与生鲜电商行业使用粘性，生鲜电商行业日人均使用次数，日人均使用时长实现双增涨，涨幅均在20%以上。
										</div>
									</div>
									<div class="project_item">
										<div class="item_text">
											<div class="point"></div>
											<div class="item_title">商城介绍</div>
										</div>
										<div class="item_remark">
											本商城是通过线上购买的商业模式，快递方式发送到自提点，从家庭消费更多属于计划性消费这一底层逻辑出发，通过快递冷链配送，降低损耗和配送成本。
										</div>
									</div>
									<div class="project_item">
										<div class="item_text">
											<div class="point"></div>
											<div class="item_title">产品定位</div>
										</div>
										<div class="item_remark">
											多粒鲜果是一款自营生鲜配送电商商城，涵盖了水果蔬菜、肉禽鸡蛋、水产海鲜、日用百货等品类。依托高效的运营模式和完善的供应链体系，将为更多消费者带去新鲜、丰富、价优、安全的生鲜食材和配送服务。
										</div>
									</div>
								</div>
								<div class="picture">
									<img src="../assets/images/pc05.png" alt="" />
								</div>
							</div>

						</div>
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<div class="pageThree">
						<div class="Three_box">
							<div class="product">产品优势
								<div class="product_img">
									<img src="../assets/images/pc03.png" alt="" />
								</div>
								<div class="product_des">Mood Board</div>
							</div>
							<div class="product_content">
								<div class="product_box">
									<div class="freshImg">
										<img src="../assets/images/pc04.png" alt="" />
										<div class="fresh_item">
											<div class="item_title">新鲜</div>
											<div class="item_desc">FRESH</div>
										</div>
									</div>
									<div class="freshImg">
										<img src="../assets/images/pc06.png" alt="" />
										<div class="fresh_item">
											<div class="item_title">丰富</div>
											<div class="item_desc">ABUNDANT</div>
										</div>
									</div>
									<div class="freshImg">
										<img src="../assets/images/pc07.png" alt="" />
										<div class="fresh_item">
											<div class="item_title">便捷</div>
											<div class="item_desc">CONVENIENT</div>
										</div>
									</div>
								</div>
								<div class="bottom">
									<!-- <div class="bottom_img">
										 <el-popover
										    placement="bottom"
										    title="标题"
										    width="200"
										    trigger="click"
										    content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
										   <img  src="../assets/images/contact2.png" alt="" />
										  </el-popover>
										
									</div> -->
									<div>©2024 四季有果 版权所有</div>
									<div>网站备案/许可证号: <span @click="toGongxinbu" class="span"> 鄂ICP备2024038352号-4
											鄂ICP备2024038352号-5A
										</span></div>
									<div>地址：武汉市洪山区光谷五路恺德中心3栋</div>
									<div>联系我们：sijiyouguo2024@126.com</div>
									<div>
										<span class="bottom_span" @click="toPrv">《隐私协议》</span>
										<span>武汉四季有果科技有限公司</span>
									</div>

								</div>
							</div>
						</div>
					</div>
				</el-carousel-item>

			</el-carousel>
		</div>
	</div>
</template>

<script>
	import QRCode from "qrcode";
	export default {
		name: "pageIndex",
		data() {
			return {
				andoroidurl: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.duolixianguoapp',
				iosurl: 'https://apps.apple.com/cn/app/%E5%A4%9A%E7%B2%92%E9%B2%9C%E6%9E%9C/id6736897092',
				logoUrl: 'https://www.sijiyouguo.com/logo.png',
				screenWidth: 0,
				screenHeight: 0,
				swiperOpen: true,
				nowIndex: true

			};
		},
		created() {
			if (!this.IsPC()) {
				this.$router.push("/phone");
			}
		},
		mounted() {
			//在mounted()方法里监听mousewheel；
			// chrome and ie
			window.addEventListener('mousewheel', this.handleScroll, false)
			//// firefox
			window.addEventListener("DOMMouseScroll", this.handleScroll, false)
			this.updateScreenSize();
			// 监听窗口大小变化事件  
			window.addEventListener('resize', this.updateScreenSize);
			this.generateAndroidQRCodeWithLogo();
			this.generateIosQRCodeWithLogo();
		},
		beforeDestroy() {
			// 组件销毁前移除事件监听器，避免内存泄漏  
			window.removeEventListener('resize', this.updateScreenSize);
		},
		methods: {
			handleScroll(e) {
				var that = this
				var direction = e.deltaY > 0 ? 'down' : 'up' //该语句可以用来判断滚轮是向上滑动还是向下
				if (this.nowIndex) {
					this.nowIndex = false
					if (direction == 'down') {
						console.log(that.$refs.carousel)
						if (typeof that.$refs.carousel !== 'undefined') {
							that.$refs.carousel.next();
						}
					} else {
						if (typeof that.$refs.carousel !== 'undefined') {
							that.$refs.carousel.prev();
						}
					}
					setTimeout(() => {
						that.nowIndex = true
					}, 100)
				}

			},
			updateScreenSize() {
				this.screenWidth = window.innerWidth;
				this.screenHeight = window.innerHeight;
			},
			// 判断是否是手机
			IsPC() {
				let userAgentInfo = navigator.userAgent;
				let Agents = [
					"Android",
					"iPhone",
					"SymbianOS",
					"Windows Phone",
					"iPad",
					"iPod",
				];
				let flag = true;
				for (let v = 0; v < Agents.length; v++) {
					if (userAgentInfo.indexOf(Agents[v]) > 0) {
						flag = false;
						break;
					}
				}
				return flag;
			},
			toPrv() {
				window.open("prvagg.html", '_blank');
			},
			toGongxinbu() {
				window.location.href = "https://beian.miit.gov.cn/"
			},
			generateAndroidQRCodeWithLogo() {
				var that = this
				// 首先生成二维码到canvas  
				const canvas = this.$refs.andoroidqrCanvas;
				QRCode.toCanvas(canvas, that.andoroidurl, {
					width: 200, // 留出空间给logo  
					height: 200
				});
				// 加载logo图片  
				const logoImage = new Image();
				logoImage.src = that.logoUrl;
				logoImage.onload = () => {
					// 计算logo的合适大小并居中放置  
					const logoSize = 50; // Logo的大小  
					const logoX = (canvas.width - logoSize) / 2;
					const logoY = (canvas.height - logoSize) / 2;
					// 使用drawImage在二维码上绘制logo  
					const ctx = canvas.getContext('2d');
					ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
				};

				// 错误处理  
				logoImage.onerror = (error) => {
					console.error('Failed to load logo image:', error);
				};
			},
			generateIosQRCodeWithLogo() {
					var that = this
				// 首先生成二维码到canvas  
				const canvas = this.$refs.iosqrCanvas;
				QRCode.toCanvas(canvas, that.iosurl, {
					width: 200, // 留出空间给logo  
					height: 200
				});
				// 加载logo图片  
				const logoImage = new Image();
				logoImage.src = that.logoUrl;
				logoImage.onload = () => {
					// 计算logo的合适大小并居中放置  
					const logoSize = 50; // Logo的大小  
					const logoX = (canvas.width - logoSize) / 2;
					const logoY = (canvas.height - logoSize) / 2;

					// 使用drawImage在二维码上绘制logo  
					const ctx = canvas.getContext('2d');
					ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
				};

				// 错误处理  
				logoImage.onerror = (error) => {
					console.error('Failed to load logo image:', error);
				};
			},
			pauseCarousel() {
				clearInterval(this.interval);
			},
			startCarousel() {

				this.$refs.carousel.next();

			}


		},


	};
</script>

<style lang="less">
	.officepc {

		.homePage {
			width: 100%;
			height: 100%;
			background: linear-gradient(135deg, #F4EED0 0%, #D9F6C3 32%, #AAF1C0 55%, #94F1DB 83%, #C3EDA4 100%);
			padding: 82px 158px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;

			.One_box {
				width: 1600px;
				height: 800px;
				// background-image: url('../assets/images/pcbg.png');
				// background-size: cover;
				// background-position: center;
				// background-repeat: no-repeat;
				border: 2px solid #fff;
				border-radius: 50px;
				position: relative;

				.headerBox {
					margin-top: 20px;
					margin-left: 18px;
					display: flex;
					align-items: center;
					height: 80px;

					.leftbox {
						display: flex;
						width: 70px;
						height: 70px;
						border-radius: 14px;

						img {
							height: 100%;
							width: 100%;
							border-radius: 14px;
						}

					}

					.rightbox {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-left: 10px;

						.topbox {
							display: flex;
							flex-direction: row;
							margin-bottom: 6px;


							.title {
								font-weight: 400;
								font-size: 36px;
								color: #24BE2A;
								letter-spacing: 7px;
								font-family: AlimamaDongFangDaKai-Regular;
							}

							.line {
								border: 1px #fff solid;
								margin: 10px 16px;

							}

							.mall {
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								justify-content: center;

								span {
									font-family: MiSans, MiSans;
									font-weight: 400;
									font-size: 16px;
									color: #24BE2A;
									letter-spacing: 8px;
								}
							}
						}

						.bbox {
							font-weight: 400;
							font-size: 16px;
							color: #7ED779;
							letter-spacing: 8px;
							font-family: MiSans-Regular;
						}
					}

				}

				.pageTitle {
					width: 747px;
					height: 225px;
					font-family: AlimamaDongFangDaKai-Regular;
					font-weight: normal;
					font-size: 180px;
					letter-spacing: 2px;
					color: #24BE2A;
					margin-top: 60px;
					margin-left: 98px;
				}

				.pageDesc {
					width: 760px;
					height: 107px;
					font-family: MiSans, MiSans;
					font-weight: bold;
					font-size: 80px;
					color: #FFFFFF;
					-webkit-text-stroke: 1px #24BE2A;
					letter-spacing: 10px;
					margin-left: 98px;
				}

				.pageBox {
					width: 560px;
					height: 96px;
					display: flex;
					justify-content: space-between;
					margin-left: 120px;
					margin-top: 52px;

					.phone {
						width: 224px;
						height: 76px;

						img {
							width: 100%;
							height: 100%;
						}
					}
				}

				.pageIcon {
					width: 265px;
					height: 218px;
					position: absolute;
					left: 700px;
					bottom: 50px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.pageFoot {
					font-family: MiSans, MiSans;
					font-weight: 500;
					font-size: 40px;
					color: #55D2A2;
					position: absolute;
					left: 85px;
					bottom: 45px;
				}

				.pagePhone {
					position: absolute;
					right: -30px;
					bottom: 70px;
					width: 750px;
					height: 750px;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.pageTwo {
			width: 100%;
			min-height: 100vh;
			background: linear-gradient(225deg, #FEFFF8 0%, #EDFFF4 53%, #E5F8F6 100%);
			padding: 82px 158px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;

			.Two_box {
				width: 1600px;
				height: 800px;

				.project {
					width: 280px;
					height: 72px;
					font-weight: 400;
					font-size: 60px;
					color: #000000;
					letter-spacing: 8px;
					text-align: left;
					-webkit-text-stroke: 2px #000000;
					white-space: nowrap;
					position: relative;

					.project_img {
						width: 70px;
						height: 70px;
						position: absolute;
						bottom: -35px;
						right: -15px;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.project_des {
						font-weight: 400;
						font-size: 32px;
						color: #FFFFFF;
						-webkit-text-stroke: 1px #000000;
						letter-spacing: 5px;
					}
				}

				.project_box {
					// position: relative;
					display: flex;
					justify-content: space-between;
					align-items: flex-end;

					.project_list {
						margin-top: 80px;
						height: 600px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.project_item {
							text-align: left;
							font-family: MiSans, MiSans;
							font-weight: 600;
							width: 1078px;
							height: 200px;

							.item_text {
								display: flex;
								align-items: center;

								.item_title {
									font-size: 36px;
									color: #000000;
									margin-left: 40px;
									line-height: 90px;
								}

								.point {
									width: 35px;
									height: 35px;
									background: #00D38B;
									border-radius: 50%;
								}
							}

							.item_remark {
								font-size: 25px;
								color: #000000;
							}
						}

					}

					.picture {
						width: 472px;
						height: 472px;
						// position: absolute;
						// right: 0px;
						// bottom: -120px;

						img {
							width: 100%;
							height: 100%;
						}
					}
				}

			}


		}

		.pageThree {
			width: 100%;
			min-height: 100vh;
			background: linear-gradient(225deg, #FEFFF8 0%, #EDFFF4 53%, #E5F8F6 100%);
			padding: 40px 158px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;

			.Three_box {
				width: 1600px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.product {
					width: 280px;
					height: 72px;
					font-weight: 400;
					font-size: 60px;
					color: #000000;
					letter-spacing: 8px;
					text-align: left;
					-webkit-text-stroke: 2px #000000;
					white-space: nowrap;
					position: relative;

					.product_img {
						width: 70px;
						height: 70px;
						position: absolute;
						bottom: -35px;
						right: -15px;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.product_des {
						font-weight: 400;
						font-size: 32px;
						color: #FFFFFF;
						-webkit-text-stroke: 1px #000000;
						letter-spacing: 8px;
					}
				}

				.product_content {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.product_box {
						width: 1600px;
						height: 460px;
						margin-top: 80px;
						display: flex;
						justify-content: space-between;

						.freshImg {
							width: 460px;
							height: 296px;
							width: calc(100% /3 - 40px);

							img {
								width: 100%;
								height: 100%;
							}

							.fresh_item {
								font-family: MiSans, MiSans;
								font-weight: 600;
								color: #07CE87;
								letter-spacing: 5px;
								margin-top: 30px;

								.item_title {
									font-size: 50px;
								}

								.item_desc {
									font-size: 30px;
								}
							}
						}
					}

					.bottom {
						font-family: MiSans, MiSans;
						font-size: 20px;
						font-weight: 600;
						color: #07CE87;
						text-align: center;
						line-height: 40px;
						display: flex;
						flex-direction: column;
						// justify-content: center;
						align-items: center;

						.bottom_img {
							width: 196px;
							height: 63px;

							img {
								width: 100%;
								height: 100%;
							}
						}

						// // margin-top: 127px;
						// margin-bottom: 48px;


						.span {
							color: #5990D0;
						}

						.bottom_span {
							color: #5990D0;
							margin-right: 20px;
						}
					}
				}
			}
		}
	}
</style>